import React from 'react';
import styled from 'styled-components';

const ServicesSection = styled.section`
  padding: 60px 20px;
  background: #2b2b2b;
  text-align: center;
`;

const Heading = styled.h2`
  font-size: 2em;
  margin-bottom: 40px;
  color: #fff;
`;

const ServiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
  }
`;

const Service = styled.div`
  max-width: 400px;
  margin: 20px;
  text-align: left;
  background: #3a3a3a; /* Changed to a lighter background */
  padding: 20px;
  border-radius: 8px;
`;

const ServiceImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
`;

const ServiceHeading = styled.h3`
  font-size: 1.5em;
  margin-top: 20px;
  color: #fff;
`;

const ServiceText = styled.p`
  font-size: 1.1em;
  color: #ccc;
`;

const Services: React.FC = () => {
  return (
    <ServicesSection id="services">
      <Heading>Our Services</Heading>
      <ServiceContainer>
        <Service>
          <ServiceImage src="/aws.png" alt="AWS Infrastructure" />
          <ServiceHeading>AWS Infrastructure Consulting</ServiceHeading>
          <ServiceText>
            Leverage the power of AWS to scale your business operations. Our team of certified AWS experts
            provides comprehensive consulting services, from initial setup and architecture design to ongoing
            management and optimization.
          </ServiceText>
        </Service>
        <Service>
          <ServiceImage src="/onprem.jpeg" alt="On-Prem Infrastructure" />
          <ServiceHeading>On-Prem Infrastructure Consulting</ServiceHeading>
          <ServiceText>
            Optimize your on-prem infrastructure to meet the demands of your business. We offer consulting
            services that cover everything from hardware selection and network design to virtualization and
            security.
          </ServiceText>
        </Service>
      </ServiceContainer>
    </ServicesSection>
  );
};

export default Services;
