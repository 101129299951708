import React from 'react';
import styled from 'styled-components';

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: #1f1f1f;
`;

const Logo = styled.h1`
  font-size: 1.5em;
  color: #fff;
`;

const NavLinks = styled.div`
  & > a {
    margin-left: 20px;
    text-decoration: none;
    color: #ddd;
    font-weight: bold;
  }
`;

const Navbar: React.FC = () => {
  return (
    <Nav>
      <Logo>Keystone Solutions</Logo>
      <NavLinks>
        <a href="#about">About</a>
        <a href="#services">Services</a>
        <a href="#contact">Contact</a>
      </NavLinks>
    </Nav>
  );
};

export default Navbar;
