import React from 'react';
import styled from 'styled-components';

const ContactSection = styled.section`
  padding: 60px 20px;
  background: #1f1f1f;
  text-align: center;
`;

const Heading = styled.h2`
  font-size: 2em;
  margin-bottom: 20px;
  color: #fff;
`;

const Text = styled.p`
  font-size: 1.1em;
  max-width: 800px;
  margin: 0 auto 20px auto;
  color: #ccc;
`;

const EmailLink = styled.a`
  color: #ddd;
  font-weight: bold;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const Contact: React.FC = () => {
  return (
    <ContactSection id="contact">
      <Heading>Contact Us</Heading>
      <Text>
        Ready to take your business to the next level with cloud solutions? Get in touch with us today.
      </Text>
      <EmailLink href="mailto:info@keystonesolutions.org">info@keystonesolutions.org</EmailLink>
    </ContactSection>
  );
};

export default Contact;
