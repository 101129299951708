import React from 'react';
import styled from 'styled-components';

const AboutSection = styled.section`
  padding: 60px 20px;
  background: #2b2b2b;
  text-align: center;
`;

const Heading = styled.h2`
  font-size: 2em;
  margin-bottom: 20px;
  color: #fff;
`;

const Text = styled.p`
  font-size: 1.1em;
  max-width: 800px;
  margin: 0 auto;
  color: #ccc;
`;

const About: React.FC = () => {
  return (
    <AboutSection id="about">
      <Heading>About Us</Heading>
      <Text>
        Keystone Solutions is a leading cloud engineering firm committed to helping businesses
        harness the power of the cloud. With years of experience and a team of dedicated experts,
        we deliver tailored solutions that drive innovation and success.
      </Text>
    </AboutSection>
  );
};

export default About;
