import React from 'react';
import styled from 'styled-components';

const HeroSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #3a3a3a;
  text-align: center;
`;

const HeroText = styled.div`
  max-width: 600px;
`;

const Heading = styled.h1`
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #fff;
`;

const SubHeading = styled.p`
  font-size: 1.2em;
  color: #ccc;
`;

const Hero: React.FC = () => {
  return (
    <HeroSection>
      <HeroText>
        <Heading>Welcome to Keystone Solutions</Heading>
        <SubHeading>Your trusted partner in cloud engineering solutions.</SubHeading>
      </HeroText>
    </HeroSection>
  );
};

export default Hero;
